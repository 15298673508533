import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaDiscord, FaTwitter } from "react-icons/fa";

// Assets and Styles
import styles from "./Footer.module.css";
import Logo from "../../asset/logo.png";
import Polygon from "../../asset/polygon.svg";
import OSLogo from "../../asset/OS logo.gif";

/**
 * A footer that displays the logo and contact information.
 * @returns A footer component.
 */

const Footer = () => {
  return (
    <Container fluid className={styles.footer}>
      <div className={styles.footer__left}>
        <img alt="Logo" src={Logo} className={styles.footer__logo} />
        <div>
          <h3 className="h3"> CHAINCARDS</h3>
          <p className="p">info@chaincards.io</p>
        </div>
      </div>
      <div className={styles.footer__right}>
        <div className={styles.footer__rightIcons}>
          <a
            href="https://twitter.com/chaincards"
            target="_blank"
            rel="noreferrer noopener"
            className={styles.footer__icon}
            aria-label="ChainCards on Twitter"
          >
            <span className={styles.social__icon}>
              <FaTwitter size={30} />
            </span>
          </a>
          <a
            href="https://discord.gg/65kusexvTR"
            target="_blank"
            rel="noreferrer noopener"
            className={styles.footer__icon}
            aria-label="ChainCards on Discord"
          >
            <span className={styles.social__icon}>
              <FaDiscord size={30} />
            </span>
          </a>
          {/* <a
            href="https://twitter.com/chaincards"
            target="_blank"
            rel="noreferrer noopener"
            className={styles.footer__icon}
          >
            <span className={styles.social__icon}>
              <img src={OSLogo} alt="OpenSea" className="img-fluid" />
            </span>
          </a> */}
        </div>
        <div className={styles.footer__rightPolygons}>
          {React.Children.toArray(
            ["E", "S", "T"].map((char) => (
              <div className={styles.footer__polygon}>
                <img src={Polygon} alt="Polygon" />
                <span>{char}</span>
              </div>
            ))
          )}
          <span>2023</span>
        </div>
      </div>
    </Container>
  );
};

export default Footer;
