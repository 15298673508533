import React from "react";
import { FaInstagram, FaTwitter } from "react-icons/fa";

// Assets and Styles
import styles from "./PurchaseModal.module.css";
import Polygon from "../../../asset/polygon-white.svg";
import OSLogo from "../../../asset/OS logo.gif";
import ESLogo from "../../../asset/etherscan-logo.gif";
import { Col } from "react-bootstrap";
/**
 * A component that displays the stats of a card.
 * @param data - The data to display.
 * @returns A component that displays the stats of a card.
 */

const StatsCard = ({ data }) => {
  const keys = Object.keys(data.metaData);

  return (
    <div className={styles.stats__card}>
      CARD INFO:
      <div className={styles.card__textList}>
        {keys.map((key, index) => (
          <div key={index} className={styles.card__text + " d-block d-xl-flex"}>
            {key !== "Card Price" &&
              key !== "Total Price" &&
              key !== "Artist" && (
                <>
                  {key} :{" "}
                  <span className={styles.box__subText}>
                    {data.metaData[key]}
                  </span>
                </>
              )}
            {key === "Card Price" && (
              <>
                <div className="mb-below-xl-2">
                  Card Price :
                  <span className={styles.box__subText}>
                    {data.metaData["Card Price"]}{" "}
                  </span>
                </div>
                <div className="ms-auto ">
                  Total :
                  <span className={styles.box__subText}>
                    {data.metaData["Total Price"]}
                  </span>
                </div>
              </>
            )}
            {key === "Artist" && (
              <>
                Artist:{" "}
                <span className={styles.box__subText}>
                  {data.metaData["Artist"]}
                </span>
                <div className={styles.artistLinksWrapper}>
                  <div className="m-0 p-0 me-1 d-block d-xl-none invisible">
                    Artist:
                  </div>
                  <a
                    href={data.socialMediaLinks["twitter"]}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div>
                      <span>
                        <FaTwitter size={17} />
                      </span>
                    </div>
                  </a>
                  <a
                    href={
                      data.socialMediaLinks[data["contractAddress"]]?.opensea
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div>
                      <span>
                        <img src={OSLogo} alt="OpenSea" className="img-fluid" />
                      </span>
                    </div>
                  </a>
                  <a
                    href={`https://etherscan.io/address/${data["contractAddress"]}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div>
                      <span>
                        <img
                          className="img-fluid"
                          src={ESLogo}
                          alt="EtherScan"
                        />
                      </span>
                    </div>
                  </a>
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default StatsCard;
