import React, { useEffect, useImperativeHandle } from "react";
import { connect } from "react-redux";

// Redux
import { setStep } from "../redux/tour/actions";

// Components
import StepOne from "./StepOne/StepOne";
import StepTwo from "./StepTwo/StepTwo";
import StepFour from "./StepFour/StepFour";
import StepFive from "./StepFive/StepFive";

const Tour = (props) => {
  const { tourRef, setStep, step } = props;

  useEffect(() => {
    const isCompleted = localStorage.getItem("tourCompleted");
    if (!isCompleted) {
      setStep(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useImperativeHandle(tourRef, () => ({
    startTour: () => {
      setStep(1);
    },
  }));

  const handleComplete = () => {
    setStep(null);
    localStorage.setItem("tourCompleted", "yes");
  };

  return (
    <React.Fragment>
      <StepOne
        isShowing={step === 1}
        onClick={() => setStep(2)}
        onHide={handleComplete}
        onStepClick={(val) => setStep(val)}
      />
      <StepTwo
        isShowing={step === 2}
        onClick={() => setStep(3)}
        onHide={handleComplete}
        onStepClick={(val) => setStep(val)}
      />
      {/* <StepThree
        isShowing={step === 3}
        onClick={() => setStep(4)}
        onHide={handleComplete}
        onStepClick={(val) => setStep(val)}
      /> */}
      <StepFour
        isShowing={step === 4}
        onClick={() => setStep(5)}
        onHide={handleComplete}
        onStepClick={(val) => setStep(val)}
      />
      <StepFive
        isShowing={step === 5}
        onClick={handleComplete}
        onHide={handleComplete}
        onStepClick={(val) => setStep(val)}
      />
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  setStep,
};

const mapStateToProps = (state) => {
  return {
    step: state.tour.step,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Tour);
