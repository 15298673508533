import React, { useEffect, useState, useRef } from "react";
import { Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Web3EthContract from "web3-eth-contract";
import { Spinner } from "react-bootstrap";
import { defaultCards as defaultCards } from "../../data/Data";

// Assets and Styles
import styles from "./PurchasedCards.module.css";

// utils
import useFilter from "../../utils/customHooks/useFilter";

// Contract
import collectionContract from "../../contractAbis/collection.json";

//  Components
import { CrossFilterButton } from "../Button/Button";
import Card from "../Card/Card";
import API from "../../utils/API";
import axios from "axios";
import Loader from "../Loader/Loader";

// CONSTANTS
const LIMIT = 5;

//Functions
const filterCardsFromBE = async (
  type,
  setOffset,
  setMyCards,
  setTotalCards,
  setFetchingMoreCards,
  setSelectedCard,
  blockchainReducer,
  dispatch
) => {
  setOffset(0);
  setMyCards([]);
  setFetchingMoreCards(true);
  dispatch(setSelectedCard(defaultCards[0]));

  if (blockchainReducer.connected) {
    try {
      const res = await axios.get(
        API +
          `get-fetchFromOS/${
            blockchainReducer.wallet.address
          }?limit=${LIMIT}&offset=${0}&type=${type}`,
        {
          headers: {
            Authorization: "Bearer" + blockchainReducer.token,
          },
        }
      );
      setMyCards(res.data.myCards);
      setTotalCards(res.data.totalCards);
      setFetchingMoreCards(false);
    } catch (error) {
      // Handle error if needed
    }
  } else {
    setMyCards([]);
    setTotalCards(0);
    setFetchingMoreCards(false);
  }
};
/**
 * Displays the cards that the user has purchased.
 * @param setSelectedCard - The function to call when a card is selected.
 * @returns PurchasedCards - A component that displays the cards that the user has purchased.
 */
const PurchasedCards = ({ setSelectedCard }) => {
  const [activePurchased, setActivePurchased] = useState(true);
  const [activeReceived, setActiveReceived] = useState(true);
  const [fetchingMoreCards, setFetchingMoreCards] = useState(false);
  const [offset, setOffset] = useState(0);
  const containerRef = useRef(null);
  const dispatch = useDispatch();
  const [myCards, setMyCards] = useState([]);
  const [totalCards, setTotalCards] = useState();
  const blockchainReducer = useSelector((state) => state.blockchain);
  const cardReducer = useSelector((state) => state.cards);
  let data = myCards;

  const { filteredData, filter } = useFilter(myCards);

  //will ony work when wallet is connected or disconnected

  useEffect(() => {
    let type =
      activePurchased && !activeReceived
        ? "purchased"
        : activeReceived && !activePurchased
        ? "received"
        : "";
    filterCardsFromBE(
      type,
      setOffset,
      setMyCards,
      setTotalCards,
      setFetchingMoreCards,
      setSelectedCard,
      blockchainReducer,
      dispatch
    );
  }, [blockchainReducer.connected]);

  //when some changes occurs
  useEffect(() => {
    const func = async () => {
      try {
        const res = await axios.get(
          API +
            `get-fetchFromOS/${blockchainReducer.wallet.address}?limit=${
              offset + LIMIT
            }&offset=${0}`,
          {
            headers: {
              Authorization: "Bearer" + blockchainReducer.token,
            },
          }
        );
        setMyCards(res.data.myCards);
        setTotalCards(res.data.totalCards);
        setFetchingMoreCards(false);
      } catch (error) {
        // Handle error if needed
      }
    };

    if (blockchainReducer.connected && cardReducer.triggerFetchCards > 0) {
      func();
    } else {
      setFetchingMoreCards(false);
      setMyCards([]);
      setTotalCards(0);
    }
  }, [cardReducer.triggerFetchCards]);
  //onscroll fetch
  useEffect(() => {
    const func = async () => {
      let type = "";
      if (activePurchased && !activeReceived) type = "purchased";
      else if (activeReceived && !activePurchased) type = "received";
      if (myCards.length > 0) {
        setFetchingMoreCards(true);
      }

      try {
        const res = await axios.get(
          API +
            `get-fetchFromOS/${blockchainReducer.wallet.address}?limit=${LIMIT}&offset=${offset}&type=${type}`,
          {
            headers: {
              Authorization: "Bearer" + blockchainReducer.token,
            },
          }
        );

        const newCards = res.data.myCards;

        setMyCards((prevState) => {
          const updatedCards = [...prevState, ...newCards];
          return updatedCards;
        });

        setTotalCards(res.data.totalCards);
      } catch (error) {
        // Handle error if needed
      }

      setFetchingMoreCards(false);
    };
    if (blockchainReducer.connected && offset !== 0) func();
    else {
      setFetchingMoreCards(false);
    }
  }, [LIMIT, offset]);

  // attach event
  useEffect(() => {
    const container = containerRef.current;

    container.addEventListener("scroll", handleScroll);

    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [myCards]);

  useEffect(() => {
    if (activePurchased && !activeReceived) {
      // filter("purchased", "status");
      filterCardsFromBE(
        "purchased",
        setOffset,
        setMyCards,
        setTotalCards,
        setFetchingMoreCards,
        setSelectedCard,
        blockchainReducer,
        dispatch
      );
    } else if (activeReceived && !activePurchased) {
      // filter("received", "status");
      filterCardsFromBE(
        "received",
        setOffset,
        setMyCards,
        setTotalCards,
        setFetchingMoreCards,
        setSelectedCard,
        blockchainReducer,
        dispatch
      );
    } else {
      // filter("*", "status");
      filterCardsFromBE(
        "",
        setOffset,
        setMyCards,
        setTotalCards,
        setFetchingMoreCards,
        setSelectedCard,
        blockchainReducer,
        dispatch
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePurchased, activeReceived]);

  const handleScroll = () => {
    const container = containerRef.current;

    const totalDistance = container.scrollHeight;
    let scrolledDistance = container.scrollTop + container.clientHeight;
    scrolledDistance += (scrolledDistance / totalDistance) * 50;

    if (
      !fetchingMoreCards &&
      totalCards > myCards.length &&
      scrolledDistance >= totalDistance
    ) {
      setOffset((prevOffset) => prevOffset + LIMIT);
    }
  };

  const handleSelection = async (id) => {
    let card = myCards.find((c) => c.id === id);

    const SmartContractObj = new Web3EthContract(
      collectionContract,
      card.contractAddress
    );
    // const  url =  await getBase64Image(card.imageUrl)

    card = { ...card, SmartContractObj };
    dispatch(setSelectedCard(card));
  };

  return (
    <>
      <Container
        ref={containerRef}
        className={styles.purchased__cardsContainer}
      >
        {blockchainReducer.connected || blockchainReducer.loading ? (
          <>
            <div className={styles.cards__Header}>
              <div className={styles.heading}>Your Cards</div>
              <div className={styles.header__right}>
                <CrossFilterButton
                  active={activePurchased}
                  label="Purchased"
                  onClick={() => setActivePurchased(!activePurchased)}
                />
                <CrossFilterButton
                  active={activeReceived}
                  label="Received"
                  onClick={() => setActiveReceived(!activeReceived)}
                />
              </div>
            </div>
            <Row className={styles.card__list}>
              {filteredData ? (
                filteredData.map((data, index) => (
                  <Card
                    key={data.id}
                    data={data}
                    purchasedCard={true}
                    onClick={() => handleSelection(data.id)}
                  />
                ))
              ) : data ? (
                data.map((data, index) => (
                  <Card
                    key={data.id}
                    data={data}
                    purchasedCard={true}
                    onClick={() => handleSelection(data.id)}
                  />
                ))
              ) : (
                <h5>No Cards Available</h5>
              )}
              {fetchingMoreCards && (
                <div className="text-center">
                  <Loader />
                </div>
              )}
            </Row>
          </>
        ) : (
          <div className={styles.cards__Header}>
            <div className={styles.heading}>
              Connect your wallet to view your cards.
            </div>
          </div>
        )}
      </Container>
    </>
  );
};

export default PurchasedCards;
