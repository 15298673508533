import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { web3 } from "../../web3ModalIns";
// Assets and Styles
import styles from "./CardEditor.module.css";
import Icon from "../../asset/watermark-chaincard.png";
import ImageModal from "../Modals/ImagePreviewModal/ImageModal";

// Utils
import { defaultCards as data } from "../../data/Data";
import useModal from "../../utils/customHooks/useModal";

// Redux
import {
  setSelectedCard,
  sendCard,
  purchaseAndSendCard,
} from "../../redux/cards/actions";

// Components
import Tour from "../../tour";
import PurchasedCards from "../PurchasedCards/PurchasedCards";
import SelectedCard from "../SelectedCard/SelectedCard";
import WipeModal from "../Modals/WipeModal/WipeModal";
import StatsCard from "../StatsCard/StatsCard";
import CrossPolygon from "../Polygon/CrossPolygon";
import { PrimaryButton } from "../Button/Button";
import Loader from "../Loader/Loader";

/**
 * The CardEditor component is a React functional component that represents
 * the main interface for creating and sending digital cards on the blockchain.
 * It includes a form for inputting the receiver's address and a text message,
 * a preview of the selected card with an option to edit the text message,
 * and buttons for sending the card, clearing the form, and opening a modal
 * to confirm the wiping of the selected card's content. The component uses
 * several sub-components, including PurchasedCards, SelectedCard, StatsCard,
 * WipeModal, ImageModal, and Tour, and also relies on Redux for state management.
 *
 * Props:
 * None
 *
 * State:
 * - sendLoading: a boolean that indicates whether the Send button is currently
 *   loading (i.e. waiting for a response from the blockchain)
 * - wipeLoading: a boolean that indicates whether the Wipe button is currently
 *   loading (i.e. waiting for confirmation from the user)
 * - showCount: a boolean that determines whether the character count for the
 *   text message should be displayed
 * - preview: a boolean that determines whether the preview modal should be displayed
 * - check: a boolean that determines whether the checkbox for wiping the card
 *   should be checked (not currently used)
 * - address: a string that represents the receiver's address input
 * - text: a string that represents the text message input
 *
 */

const CardEditor = () => {
  const [sendLoading, setSendLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [wipeLoading, setWipeLoading] = useState(false);
  const [showCount, setShowCount] = useState(false);
  const [preview, setPreview] = useState(false);
  const [check, setCheck] = useState(true);
  const [address, setAddress] = useState("");
  const [text, setText] = useState("");

  const blockchainReducer = useSelector((state) => state.blockchain);
  const awsKey = useSelector((state) => state.cards.awsKey);
  const selectedCard = useSelector((state) => state.cards.selectedCard);
  const triggerCardEdit = useSelector((state) => state.cards.triggerCardEdit);
  const dispatch = useDispatch();

  const clearRef = useRef(null);
  const tourRef = useRef(null);
  const exportRef = useRef();

  const { isShowing, show, hide } = useModal();

  useEffect(() => {
    setText("");
    dispatch(setSelectedCard(data[0]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerCardEdit, blockchainReducer.triggerChangeAccount]);

  const hidePreview = () => {
    setPreview(false);
  };

  const showPreview = () => {
    if (text.length === 0 || text.trim().length === 0) {
      throw Error("Card cannot be empty");
    }
    setPreview(true);
  };
  const throwResolverError = () => {
    const errorMessage = {
      name: "resolver",
      message: "Please enter a valid recipient's wallet address.",
    };
    throw errorMessage;
  };

  const handleExportImage = async () => {
    setShowCount(false);
    setSendLoading(true);
    let receiverAddr;
    const ensParts = address.split(".");
    try {
      if (selectedCard.metaData["Token ID"] === "-1") {
        throw Error("Purchase or select an available card to get started.");
      }
      if (selectedCard.status === "sold") {
        throw Error("All cards are sold.");
      }

      if (ensParts.length === 2) {
        const resolverAddr = await web3.eth.ens.getResolver(address);
        if (
          resolverAddr._address === "0x0000000000000000000000000000000000000000"
        ) {
          throw throwResolverError();
        }
        const addr = await web3.eth.ens.getAddress(address);
        receiverAddr = addr;
      } else {
        receiverAddr = address;
      }
      if (receiverAddr === blockchainReducer.account) {
        throw Error("You cannot send a card to yourself.");
      }
      if (!selectedCard) {
        throw Error("Please choose a card first to send.");
      }
      if (text === "" || text.trim().length === 0) {
        if (selectedCard.status === "received") {
          setText(selectedCard.cardText);
        } else {
          throw Error("Card cannot be blank!");
        }
      }
      let IsSuccess = false;
      // check if the card is of type "purchased", "received"
      if (
        selectedCard.status === "purchased" ||
        selectedCard.status === "received"
      ) {
        IsSuccess = await dispatch(
          sendCard(
            receiverAddr,
            selectedCard,
            blockchainReducer.account,
            text,
            blockchainReducer.wallet.id,
            blockchainReducer.token,
            awsKey
          )
        );
      } else if (selectedCard.status === "sold") {
        throw Error("All cards are sold.");
      } else {
        IsSuccess = await dispatch(
          purchaseAndSendCard(
            receiverAddr,
            selectedCard,
            blockchainReducer.account,
            text,
            blockchainReducer.wallet.id,
            blockchainReducer.token,
            awsKey
          )
        );
      }

      if (IsSuccess) {
        setAddress("");
      }
    } catch (err) {
      if (err.name === "resolver") {
        setSendLoading(false);
      } else {
        setSendLoading(false);
        handleClear();
      }
      toast.error(err.message || "Could not send card!");
    } finally {
      setSendLoading(false);
    }
  };

  const handleAddressCopy = () => {
    const address = document.getElementById("address").value;
    navigator.clipboard.writeText(address);
    toast.success("Address copied to clipboard!");
  };

  const handleClear = () => {
    clearRef.current.value = null;
    setText("");
  };

  const handleWipe = () => {
    show();
  };

  return (
    <>
      <Container id={styles.card__editor} fluid className="no-gutters">
        <Row>
          <Col lg={9} md={8} sm={12} className={styles.card__editorLeft}>
            <div className="d-flex justify-content-end mb-2">
              <div
                onClick={() => tourRef.current.startTour()}
                className="d-none d-md-block"
              >
                <img
                  src={Icon}
                  style={{
                    width: 20,
                    height: 20,
                    cursor: "pointer",
                  }}
                  alt="icon"
                />
              </div>
            </div>

            {selectedCard && (
              <SelectedCard
                exportRef={exportRef}
                data={selectedCard}
                setText={setText}
                text={text}
                clearRef={clearRef}
                showCount={showCount}
                setShowCount={setShowCount}
              />
            )}
            <div className={styles.editor__leftBottom}>
              <Row>
                <Col sm={12} lg={6} md={12}>
                  {selectedCard && (
                    <StatsCard
                      data={{
                        Artist: selectedCard.metaData["Artist"],
                        "Edition Size": selectedCard.metaData["Edition Size"],
                        "Token ID": selectedCard.metaData["Token ID"],
                        Series: selectedCard.metaData["Series"],
                        Category: selectedCard.metaData["Category"],
                        ...(selectedCard?.status === "live" ||
                        selectedCard?.status === "sold"
                          ? {
                              "Card Price":
                                selectedCard.metaData["Price"],
                            }
                          : {}),
                      }}
                    />
                  )}
                </Col>
                <Col sm={12} lg={6} md={12}>
                  <div className={styles.left__BottomInput}>
                    {selectedCard && selectedCard.status === "received" ? (
                      <input
                        id="address"
                        placeholder="Input Receiver Address"
                        value={selectedCard.senderAddress}
                      />
                    ) : (
                      <>
                        <input
                          id="address"
                          placeholder="Input Receiver Address"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                        />
                      </>
                    )}
                  </div>
                  <ToastContainer />
                  <div className={styles.bottom__primaryButtons}>
                    <div
                      className={styles.button__container}
                      style={{ paddingRight: "10px" }}
                    >
                      {selectedCard && selectedCard.status === "received" ? (
                        <div className={styles.button__container}>
                          {wipeLoading ? (
                            <Loader />
                          ) : (
                            <PrimaryButton
                              label="Wipe Card"
                              onClick={handleWipe}
                            />
                          )}
                        </div>
                      ) : (
                        <PrimaryButton onClick={handleClear} label="Clear" />
                      )}
                    </div>
                    <div className={styles.button__container}>
                      {selectedCard && selectedCard.status === "received" ? (
                        <PrimaryButton
                          label="Copy Sender Address"
                          onClick={handleAddressCopy}
                        />
                      ) : (
                        <>
                          {sendLoading ? (
                            <Loader />
                          ) : (
                            <PrimaryButton
                              label={
                                selectedCard?.status === "live"
                                  ? "Purchase & Send"
                                  : selectedCard?.status === "sold"
                                  ? "Sold"
                                  : "Send"
                              }
                              onClick={handleExportImage}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  {/* <div className={styles.bottom__buttonGroup}>
                    <CrossPolygon
                      check={check}
                      onClick={() => setCheck(!check)}
                      cardEditor={true}
                    />
                    <button className={styles.share__btn}>
                      Share on Twitter
                    </button>
                    {selectedCard && selectedCard.status === "received" ? (
                      <div className={styles.button__container}>
                        {wipeLoading ? (
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        ) : (
                          <PrimaryButton
                            label="Wipe Card"
                            onClick={handleWipe}
                          />
                        )}
                      </div>
                    ) : (
                      <div className={styles.button__container}>
                        <PrimaryButton label="Preview" onClick={showPreview} />
                      </div>
                    )}
                  </div> */}
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={3} md={4} sm={12} className={styles.card__editorRight}>
            <PurchasedCards setSelectedCard={setSelectedCard} />
          </Col>
        </Row>
      </Container>
      <WipeModal card={selectedCard} isShowing={isShowing} onHide={hide} />
      <ImageModal
        text={text}
        card={selectedCard}
        isShowing={preview}
        onHide={hidePreview}
      />
      <Tour tourRef={tourRef} />
    </>
  );
};

export default CardEditor;
