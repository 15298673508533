import React, { startTransition, useEffect, useState } from "react";
import { Modal, Row, Col, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

// Assets and Styles
import styles from "./PurchaseModal.module.css";

// Redux
import { privateMintCard, purchaseCard } from "../../../redux/cards/actions";

// Components
import { PrimaryButton } from "../../Button/Button";
import CrossPolygon from "../../Polygon/CrossPolygon";
import StatsCard from "./StatsCard";
import Quantity from "./Quantity";
import Loader from "../../Loader/Loader";
import { toast } from "react-toastify";
import { SearchInput } from "../../Input/Input";

/**
 * A modal that displays the product information and allows the user to purchase it.
 * @param purchasing - Whether the user is currently purchasing the product.
 * @param setPurchasing - A function that sets whether the user is currently purchasing the product.
 * @param data - The product data.
 * @param isShowing - Whether the modal is currently showing.
 * @param onHide - A function that hides the modal.
 * @returns A modal that displays the product information and allows the user to purchase it.
 */

const PurchaseModal = ({
  purchasing,
  setPurchasing,
  data,
  isShowing,
  onHide,
  setData,
  address,
  ethPrice,
}) => {
  const [quantity, setQuantity] = useState(1);
  const dispatch = useDispatch();
  const [cardPrice, setCardPrice] = useState(
    ("" + data.metaData["Card Price"]).split(" ")[0]
  );
  const [cardReceiver, setCardReceiver] = useState(address);
  const blockchain = useSelector((state) => state.blockchain);

  useEffect(() => {
    setData((prevData) => {
      const newData = { ...prevData };
      const total = newData.metaData["Remaining"]
        ? (cardPrice * quantity).toFixed(4)
        : 0;
      const totalPrice =
        total +
        " eth (" +
        (ethPrice
          ? (ethPrice * total).toFixed(2) + " USD)"
          : newData.metaData["Price"]);
      newData.metaData["Total Price"] = totalPrice;
      return newData;
    });
  }, [quantity, cardPrice]);

  const purchaseHandler = async () => {
    if (data.metaData["Remaining"]) {
      setPurchasing(true);
      try {
        let a = await dispatch(
          purchaseCard(data, quantity, blockchain.wallet, blockchain.token)
        );
        onHide();
      } catch (err) {
        setPurchasing(false);
      } finally {
        setPurchasing(false);
      }
    } else {
      toast.error("Card Sold Out.");
    }
  };
  const privateMintHandler = async () => {
    if (data.metaData["Remaining"] && cardReceiver) {
      setPurchasing(true);
      try {
        let a = await dispatch(
          privateMintCard(
            data,
            quantity,
            blockchain.wallet,
            cardReceiver,
            blockchain.token
          )
        );
        onHide();
      } catch (err) {
        setPurchasing(false);
      } finally {
        setPurchasing(false);
      }
    } else {
      toast.error("Could not mint card.");
    }
  };
  return (
    <Modal
      className={styles.modal__container}
      show={isShowing}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className={styles.modal}>
        <Row>
          <div className={styles.model__closeIcon}>
            <CrossPolygon onClick={onHide} cardEditor={false} />
          </div>
          <Col lg={7} md={12} sm={12}>
            <div className={styles.modal__card}>
              <div
                className={styles.card__imageContainer}
                style={{
                  backgroundImage: `url(${data && data.imageUrl})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <img
                  className={styles.card__image}
                  src={data && data.imageUrl}
                  style={{
                    opacity: 0,
                  }}
                  alt="CardImage"
                />
              </div>
            </div>
          </Col>
          <Col lg={5} md={12} sm={12}>
            <StatsCard data={data} />
            <div className={styles.modal__quantityBox}>
              <Quantity
                quantity={quantity}
                maxQuantity={
                  data.metaData["Remaining"] >= data.maxMintsPerTx
                    ? data.maxMintsPerTx
                    : data.metaData["Remaining"]
                }
                setQuantity={setQuantity}
              />
              {address === "0x5D307b9FD27CdeD1E0F72eDe3c0B3269b603bdF0" && (
                <div className="d-flex mt-2">
                  <span className={styles.purchase__cardReceiver}>
                    Receiver
                  </span>
                  <SearchInput
                    type="text"
                    value={cardReceiver}
                    onType={(e) => {
                      setCardReceiver(e.target.value);
                    }}
                  />
                </div>
              )}
            </div>
            <div className={styles.purchase__modalbutton}>
              {address === "0x5D307b9FD27CdeD1E0F72eDe3c0B3269b603bdF0" && (
                <Col className={styles.purchaseCol}>
                  {purchasing ? (
                    <></>
                  ) : (
                    <PrimaryButton
                      label="Private Mint"
                      onClick={privateMintHandler}
                    />
                  )}
                </Col>
              )}
              {purchasing ? (
                <Loader />
              ) : (
                <PrimaryButton onClick={purchaseHandler} label="Purchase" />
              )}
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default PurchaseModal;
