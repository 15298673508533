import React from "react";
import Loader from "../Loader/Loader";

// Assets and Styles
import styles from "./Button.module.css";
import Polygon from "../../asset/polygon-white.svg";

/**
 * A button that can be used to submit a form.  It can also be used to indicate that a form is loading.
 * @param loading - A boolean indicating whether the button is loading.  This is used to indicate that the button is loading.
 * @param label - The label to display on the button.  This is used to display the label on the button.
 * @param onClick - The function to call when the button is clicked.  This is used to call the function when the button is clicked.
 * @returns A button that can be used to submit a form.  It can also be used to indicate that a form is loading.
 */

export const PrimaryButton = ({ loading, label, onClick }) => {
  return (
    <button className={styles.primary__button} onClick={onClick}>
      {loading ? <Loader /> : label}
    </button>
  );
};

export const FilterButton = ({ label, onClick, active }) => {
  return (
    <button
      className={
        active === label ? styles.filter__buttonActive : styles.filter__button
      }
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export const CrossFilterButton = ({ label, onClick, active }) => {
  return (
    <div
      className={
        active === label.toLowerCase()
          ? styles.filter__crossActive
          : styles.filter__cross
      }
    >
      <div className={styles.polygon}>
        <img src={Polygon} alt="Polygon" />
        {active ? (
          <>
            <input
              className={styles.filterCheckBox}
              type="checkbox"
              defaultChecked={true}
              onClick={onClick}
            />
          </>
        ) : (
          <>
            <input
              className={styles.filterCheckBox}
              type="checkbox"
              defaultChecked={false}
              onClick={onClick}
            />
          </>
        )}
      </div>
      {label}
    </div>
  );
};
