import React from "react";

// Assets and Styles
import styles from "./CrossPolygon.module.css";
import Polygon from "../../asset/polygon-white.svg";

/**
 * A component that renders a cross polygon.  It can be used to either check or uncheck a
 * filter.
 */

const CrossPolygon = ({ onClick, check, cardEditor }) => {
  return (
    <div className={styles.polygon}>
      <img src={Polygon} alt="Polygon" />
      {cardEditor ? (
        check ? (
          <span onClick={onClick}>X</span>
        ) : (
          <span onClick={onClick} style={{ color: "transparent" }}>
            X
          </span>
        )
      ) : (
        <span className={styles.span} onClick={onClick}>
          X
        </span>
      )}
    </div>
  );
};

export default CrossPolygon;
