import React, { useState, useEffect } from "react";
import { Modal, Row, Spinner, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

// Assets and Styles
import styles from "./ImageModal.module.css";
import CrossPolygon from "../../Polygon/CrossPolygon";

//  Redux
import { previewImage } from "../../../redux/cards/actions";
import Loader from "../../Loader/Loader";

/**
 * A React component that renders a modal that displays the image of the card.
 * @param card - The card object that the image is being displayed for.
 * @param isShowing - A boolean that indicates whether the modal is showing.
 * @param onHide - A function that closes the modal.
 * @param text - The text to display in the modal.
 * @returns A React component that renders a modal that displays the image of the card.
 */

const ImageModal = ({ card, isShowing, onHide, text }) => {
  const [src, setSrc] = useState("");
  const [loading, setLoading] = useState(false);

  const blockchainReducer = useSelector((state) => state.blockchain);
  const awsKey = useSelector((state) => state.cards.awsKey);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isShowing) {
      processingHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowing]);

  const processingHandler = async () => {
    setLoading(true);
    try {
      const key = awsKey || null;
      let a = await dispatch(
        previewImage(
          { key, text, imageUrl: card.imageUrl },
          blockchainReducer.token
        )
      );
    } catch (err) {
    } finally {
      setSrc("");
      setLoading(false);
    }
  };

  const replaceImage = (error) => {
    error.target.src =
      card.previewImage +
      "?" +
      Date.now().toString() +
      Math.random().toString(36);
  };

  let content = loading ? (
    <div className={styles.card__spinnerContainer}>
      <Loader />
    </div>
  ) : (
    <div className={styles.card__imageContainer}>
      <Image
        className={styles.card__image}
        src={isShowing ? src : null}
        key={Date.now().toString() + Math.random().toString(36)}
        alt="CardImage"
        onError={replaceImage}
      />
    </div>
  );
  return (
    <Modal
      show={isShowing}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className={styles.modal}>
        <Row>
          <div className={styles.modal__closeIcon}>
            <CrossPolygon onClick={onHide} cardEditor={false} />
          </div>
          <Row>{content}</Row>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ImageModal;
