import { applyMiddleware, combineReducers } from "redux";
import { legacy_createStore as createStore } from "redux";
import thunk from "redux-thunk";
import blockchainReducer from "./User/reducer";
import tourReducer from "./tour/reducer";
import cardsReducer from "./cards/reducers";
import { composeWithDevTools } from "redux-devtools-extension";

const rootReducer = combineReducers({
  blockchain: blockchainReducer,
  tour: tourReducer,
  cards: cardsReducer,
});

const middleware = [thunk];
const composeEnhancers = composeWithDevTools(applyMiddleware(...middleware));

const configureStore = () => {
  return createStore(rootReducer, composeEnhancers);
};

const store = configureStore();

export default store;
