import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";

import animationData from "../../asset/loader.json";

const Loader = () => {
  return (
    <Player
      src={animationData}
      className="player"
      loop
      autoplay
      style={{ height: "50px", width: "50px" }}
    />
  );
};

export default Loader;
