import React from "react";

// Styles
import styles from "./StatsCard.module.css";

/**
 * A component that displays the stats of the page.
 * @param data - The data to display.
 * @returns A component that displays the stats of the page.
 */

const StatsCard = ({ data }) => {
  const keys = Object.keys(data);
  return (
    <div className={styles.stats__card}>
      PROPERTIES:
      <div className={styles.card__boxList}>
        {keys.map((key, index) => (
          <div
            key={index}
            className={`${styles.card__box} ${
              key === "Card Price" ? styles.price : ""
            }`}
          >
            {key !== "Card Price" ? key : ""}
            <span className={styles.box__subText}>{data[key]}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StatsCard;
