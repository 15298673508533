import React, { useEffect } from "react";
import { Modal, Row } from "react-bootstrap";

// Assets and Styles
import styles from "./stepFive.module.css";
import PurchaseGif from "../../asset/gifs/send-cards.gif";

// Components
import CrossPolygon from "../../component/Polygon/CrossPolygon";
import { PrimaryButton } from "../../component/Button/Button";

const StepOne = ({ isShowing, onHide, onClick, onStepClick }) => {
  useEffect(() => {
    if (isShowing) {
      window.scrollTo({
        top: document.body.scrollHeight / 35,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [isShowing]);

  return (
    <Modal show={isShowing} size="lg" centered className={styles.five_modal}>
      <div className={styles.five_wrapper}>
        <div className={styles.close_icon}>
          <CrossPolygon onClick={onHide} cardEditor={false} />
        </div>
        <Modal.Body>
          <Row>
            <h5 className={styles.subtext}>
              Craft your message, enter the receiver
            </h5>
            <h5 className={styles.subtext}>
              address and hit <b>'Send.'</b>
            </h5>

            <div className={styles.raised_box}>
              <img
                className="img-fluid"
                src={PurchaseGif}
                style={{ width: "100%", height: "100%" }}
                alt="Write Message"
              />
            </div>

            <div className="d-flex flex-row justify-content-between align-items-center">
              <div className="d-flex">
                <div
                  className={styles.first_dot}
                  onClick={() => onStepClick(1)}
                ></div>
                <div
                  className={styles.dot}
                  onClick={() => onStepClick(2)}
                ></div>
                <div
                  className={styles.dot}
                  onClick={() => onStepClick(3)}
                ></div>
                <div
                  className={styles.dot}
                  onClick={() => onStepClick(4)}
                ></div>
                <div className={styles.last_dot}></div>
              </div>
              <div>
                <PrimaryButton label="Got it!" onClick={onClick} />
              </div>
            </div>
          </Row>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default StepOne;
