const initialState = {
  loading: false,
  myCards: [],
  collections: [],
  triggerCardEdit: 0,
  selectedCard: null,
  awsKey: null,
  totalCollections: 0,
  triggerFetchCards: 0,
};

/**
 * Reducer for the cards state.
 * @param {object} state - The current state of the cards state.
 * @param {object} action - The action to be performed.
 * @returns {object} The new state of the cards state.
 */
const cardsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SELECTED_CARD":
      return {
        ...state,
        selectedCard: action.payload,
      };
    //trigger
    case "WIPE_CARD_SUCCESS":
      let updatedSelectedCard =
        state.selectedCard.id === action.payload.cardId
          ? {
            ...state.selectedCard,
            status: action.payload.status,
            cardText: action.payload.cardText,
          }
          : state.selectedCard;

      return {
        ...state,
        selectedCard: updatedSelectedCard,
        triggerFetchCards: state.triggerFetchCards + 1,
      };
    //trigger
    case "SEND_CARD_SUCCESS":
      return {
        ...state,
        triggerCardEdit: state.triggerCardEdit + 1,
        awsKey: null,
        triggerFetchCards: state.triggerFetchCards + 1,
      };
    case "LOADING":
      return {
        ...state,

        loading: true,
      };
    //trigger
    case "UPDATE_MY_CARDS":
      const updatedCollection = [...state.collections];

      const purchasedCollectionIndex = updatedCollection.findIndex(
        (collection) => {
          return (
            collection.contractAddress ===
            action.payload.newCards[0].contractAddress
          );
        }
      );

      const purchasedCollection = updatedCollection[purchasedCollectionIndex];

      const remaining = purchasedCollection.metaData.Remaining;

      const updatedPurchasedCollection = {
        ...purchasedCollection,
        metaData: {
          ...purchasedCollection.metaData,
          Remaining: remaining - action.payload.newCards.length,
        },
      };

      const newCollections = [
        ...updatedCollection.slice(0, purchasedCollectionIndex),
        updatedPurchasedCollection,
        ...updatedCollection.slice(purchasedCollectionIndex + 1),
      ];

      return {
        ...state,
        collections: newCollections,
        loading: false,
        triggerFetchCards: state.triggerFetchCards + 1,
      };

    case "UPDATE_REMAINING_CARDS":
      const updatedCollections = [...state.collections];
      return {
        ...state,
        collections: updatedCollections.map((collection) => {
          if (collection.id === action.payload.collectionId) {
            return {
              ...collection,
              metaData: {
                ...collection.metaData,
                Remaining: action.payload.remaining,
                "Token ID": action.payload.tokenId,
              },
            };
          } else {
            return collection;
          }
        }),
      };

    case "CLEAR_COLLECTION":
      return {
        ...state,
        collections: [],
        loading: false,
      };

    case "GET_COLLECTION_SUCCESS":
      return {
        ...state,
        collections: action.payload.replace
          ? [...action.payload.collections]
          : [...state.collections, ...action.payload.collections],
        totalCollections: action.payload.totalCollections,
        loading: false,
      };

    case "SET_PREVIEW_IMAGE":
      let imageUrlArray = action.payload.split("/");
      let key = imageUrlArray[imageUrlArray.length - 1];
      let updatedStateIP = { ...state };
      let updatedSelectedCardForPI = {
        ...updatedStateIP.selectedCard,
        previewImage: action.payload,
      };

      return {
        ...state,
        awsKey: key,
        selectedCard: updatedSelectedCardForPI,
      };

    default:
      return state;
  }
};

export default cardsReducer;
