import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import WalletLink from "walletlink";
import Web3 from "web3";
const providerOptions = {
  binancechainwallet: {
    package: true,
  },
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      infuraId: process.env.REACT_APP_INFURA_KEY, // required
    },
  },

  walletlink: {
    package: WalletLink,
    options: {
      appName: "abc",
      infuraId: process.env.REACT_APP_INFURA_KEY,
      rpc: "",
      chainId: "4",
      applogo: null,
      darkmode: true,
    },
  },
};

export const web3Modal = new Web3Modal({
  network: "rinkeby", // optional
  theme: "dark",
  cacheProvider: true, // optional
  providerOptions, // required
});
export const web3 = new Web3(`${process.env.REACT_APP_INFURA_URL}`);
