import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// Styles
import "./App.css";

//web3
import { web3Modal } from "./web3ModalIns";
import { providers } from "ethers";

//redux
import {
  connectionFailed,
  connectRequest,
  connectWeb3,
} from "./redux/User/actions";

// Components
import NavBar from "./component/Navbar/Navbar";
import Home from "./container/Home/Home";

const App = () => {
  const dispatch = useDispatch();
  const blockchainReducer = useSelector((state) => state.blockchain);
  const step = useSelector((state) => state.tour.step);

  useEffect(() => {
    async function IfConnected() {
      // connect automatically and without a popup if user is already connected

      if (!blockchainReducer.loading && web3Modal && web3Modal.cachedProvider) {
        try {
          dispatch(connectRequest());
          let connection = await web3Modal.connect();
          const ethersProvider = new providers.Web3Provider(connection);
          dispatch(connectWeb3(ethersProvider, connection));
        } catch (err) {
          dispatch(connectionFailed());
        }
      }
    }
    setTimeout(IfConnected, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockchainReducer.triggerChangeAccount]);

  return (
    <React.Fragment>
      {step === 3 && (
        <div
          style={{
            position: "fixed",
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: 2,
            width: "100%",
            height: window.innerHeight * 5,
            overflow: "hidden",
          }}
        ></div>
      )}
      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
};

export default App;
