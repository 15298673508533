import Image1 from "../asset/ChainCards-Animation-Test.gif";

//Static Data for Testing Cards
export const availableCards = [
  {
    id: 1,
    image: Image1,
    metaData: {
      artist: "Difter",
      "edition size": "Open edition",
      "release date": "01 June 2022",
      remaining: "Not applicable",
    },
    keyword: "GM",
  },
];

export const defaultCards = [
  {
    id: 6,
    imageUrl: Image1,
    metaData: {
      Artist: "Chaincards",
      "Edition Size": "01",
      // "release date": "09 June 2022",
      "Token ID": "-1",
      // "mint date": "1 June 2022",
      Series: "Intro",
      Category: "Welcome",
    },
    tags: "Intro",
    status: "purchased",
  },
];

export const keywords = ["All Cards", "GM", "Season 1"];

export const placeholder = `Hey there,


Welcome to ChainCards! To get started, simply connect your wallet and explore our marketplace to discover the perfect card. It's time to brighten someone's day with a personalized ChainCard.


Warm regards,
The CC team`;
