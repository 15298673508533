import React from "react";
import { Modal, Row } from "react-bootstrap";

// Assets and Styles
import styles from "./stepOne.module.css";
import Watermark from "../../asset/watermark.svg";

// Components
import CrossPolygon from "../../component/Polygon/CrossPolygon";
import { PrimaryButton } from "../../component/Button/Button";

const StepOne = ({ isShowing, onHide, onClick, onStepClick }) => {
  return (
    <Modal show={isShowing} size="lg" centered className={styles.one_modal}>
      <div className={styles.wrapper}>
        <img src={Watermark} className={styles.watermark} alt="watermark" />
        <div className={styles.close_icon} onClick={onHide}>
          <CrossPolygon cardEditor={false} />
        </div>
        <Modal.Body>
          <Row>
            <h5 className={styles.title}>Take a quick tour of ChainCards?</h5>
            <h5 className={styles.subtext}>
              Walk through the application and learn to send
            </h5>
            <h5 className={styles.subtext + " " + styles.mb_60}>
              your first card.
            </h5>

            <div className="d-flex flex-row justify-content-between align-items-center">
              <div className="d-flex">
                <div className={styles.first_dot}></div>
                <div
                  className={styles.dot}
                  onClick={() => onStepClick(2)}
                ></div>
                <div
                  className={styles.dot}
                  onClick={() => onStepClick(3)}
                ></div>
                <div
                  className={styles.dot}
                  onClick={() => onStepClick(4)}
                ></div>
                <div
                  className={styles.dot}
                  onClick={() => onStepClick(5)}
                ></div>
              </div>
              <div>
                <PrimaryButton label="Next" onClick={onClick} />
              </div>
            </div>
          </Row>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default StepOne;
