import React from "react";
import InlineCard from "../InlineCard/InlineCard";

// Assets and Styles
import styles from "./Card.module.css";

/**
 * A component that renders a card.  It is used to render either available or purchased cards.
 * @param data - The data for the card.  This is used to render the image.  It is also used to
 * determine if the card is purchased.  It is also used to determine if the card is available.
 * @param purchasedCard - A boolean that determines if the card is purchased.  If it is, it will
 * render the card as a purchased card.  If it is not, it will render the card as an available
 * card.  This is used to determine which class to apply to the card.
 * @param onClick - A function that is called when the
 */
const Card = ({
  data,
  purchasedCard,
  onClick,
  showArtistName,
  showSupply,
  ethPrice,
}) => {
  return (
    <>
      <InlineCard
        cardText={data.cardText}
        cardImage={data.imageUrl}
        onClick={onClick}
        showArtistName={showArtistName}
        artistName={data.metaData["Artist"]}
        editionSize={data.metaData["Edition Size"]}
        remainingSupply={data.metaData["Remaining"]}
        showSupply={showSupply}
        cardPrice={data.metaData["Card Price"]}
        ethPrice={ethPrice}
        className={
          purchasedCard
            ? styles.purchasedCard__image
            : styles.availableCard__image
        }
        variant="top"
      />
    </>
  );
};

export default Card;
