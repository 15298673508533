import axios from "axios";
import Web3 from "web3";
import Web3EthContract from "web3-eth-contract";
import { web3Modal } from "../../web3ModalIns";
import API from "../../utils/API";

export const connectRequest = () => {
  return {
    type: "CONNECTION_REQUEST",
  };
};

const connectSuccess = (payload) => {
  return {
    type: "CONNECTION_SUCCESS",
    payload: payload,
  };
};
export const changeAccount = () => {
  return { type: "ACCOUNTS_CHANGED" };
};
export const connectionFailed = () => {
  return {
    type: "CONNECTION_FAILED",
  };
};

export const disconnected = () => {
  return {
    type: "DISCONNECTED",
  };
};

export const connectWeb3 = (provider, connection) => {
  return async (dispatch) => {
    dispatch(connectRequest());
    // let web3 = new Web3(provider);

    try {
      Web3EthContract.setProvider(window.ethereum);

      const accounts = await provider.listAccounts();

      let balance = await provider.getBalance(accounts[0]);

      balance = Web3.utils.fromWei(String(balance), "ether");
      let addressString = `${accounts[0].slice(0, 5)}...${accounts[0].slice(
        accounts[0].length - 4,
        accounts[0].length
      )}`;

      connection.on("accountsChanged", async (accounts) => {
        if (accounts.length > 0) {
          dispatch(changeAccount());
        } else {
          web3Modal.clearCachedProvider();
          dispatch(disconnected());
        }
      });

      connection.on("chainChanged", () => {
        window.location.reload();
      });

      //server login

      //checking if user exists
      let res = await axios({
        method: "GET",
        url: API + "get-noice/" + accounts[0],
        validateStatus: () => true,
      });
      let data = res.data;
      //creating new if not exists
      if (res.status !== 200) {
        res = await axios.post(API + "signup/", {
          address: accounts[0],
        });
        data = res.data;
      }

      let user = data.wallet;
      try {
        let savedSignArr = JSON.parse(
          localStorage.getItem("chaincardsignerArr")
        );
        let savedSign = null;
        if (savedSignArr) {
          if (Array.isArray(savedSignArr)) {
            savedSign = savedSignArr.find((e, i) => {
              return e.id === user.id;
            });
          }
        }

        if (savedSign) {
          res = await axios.post(API + "signin/", {
            address: accounts[0],
            signature: savedSign.sign,
          });
        }
      } catch (err) {}

      if (!res.data.success) {
        let sign = await handleSignMessage(user.address, user.nonce);
        res = await axios.post(API + "signin/", {
          address: accounts[0],
          signature: sign,
        });
        let smArray = JSON.parse(localStorage.getItem("chaincardsignerArr"));
        if (Array.isArray(smArray)) {
          let el = smArray.find((e) => e.id === user.id);
          if (el) {
            smArray[smArray.indexOf(el)] = { id: user.id, sign: sign };
            localStorage.setItem("chaincardsignerArr", JSON.stringify(smArray));
          } else {
            smArray.push({ id: user.id, sign: sign });
            localStorage.setItem("chaincardsignerArr", JSON.stringify(smArray));
          }
        } else {
          smArray = [];
          smArray.push({ id: user.id, sign: sign });
          localStorage.setItem("chaincardsignerArr", JSON.stringify(smArray));
        }
      }

      function handleSignMessage(publicAddress, nonce) {
        // Define instance of web3
        var web3 = new Web3(window.ethereum);
        return new Promise((resolve, reject) =>
          web3.eth.personal.sign(
            web3.utils.fromUtf8(`Nonce: ${nonce}`),
            publicAddress,
            (err, signature) => {
              if (err) return reject(err);
              return resolve({ publicAddress, signature });
            }
          )
        );
      }

      data = res.data;
      let token = data.token;

      dispatch(
        connectSuccess({
          token: token,
          account: accounts[0],
          balance,
          addressString,
          wallet: data.wallet,
        })
      );
    } catch (err) {
      dispatch(connectionFailed());
    }
  };
};
