import React from "react";

// Assets and Styles
import styles from "./Input.module.css";

/**
 * A button that can be used to submit a form.  It can also be used to indicate that a form is loading.
 * @param loading - A boolean indicating whether the button is loading.  This is used to indicate that the button is loading.
 * @param label - The label to display on the button.  This is used to display the label on the button.
 * @param onClick - The function to call when the button is clicked.  This is used to call the function when the button is clicked.
 * @returns A button that can be used to submit a form.  It can also be used to indicate that a form is loading.
 */

export const SearchInput = ({ label, onType, type, value }) => {
  return (
    <input
      placeholder={label}
      type={type}
      className={styles.primary__button}
      onChange={onType}
      value={value}
    />
  );
};
