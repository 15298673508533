import React, { useEffect } from "react";
import { Row } from "react-bootstrap";

// Styles and Assets
import styles from "./stepThreeModal.module.css";
import PurchaseGif from "../../asset/gifs/purchase-cards.gif";

// Components
import CrossPolygon from "../../component/Polygon/CrossPolygon";
import { PrimaryButton } from "../../component/Button/Button";

/**
 * A modal that displays the third step of the tutorial.
 * @param {boolean} isShowing - whether or not the modal is showing.
 * @param {Function} onHide - a function to call when the modal is hidden.
 * @param {Function} onClick - a function to call when the user clicks the next button.
 * @param {Function} onStepClick - a function to call when the user clicks the step button.
 * @returns None
 */

const StepThreeModal = ({ isShowing, onHide, onClick, onStepClick }) => {
  useEffect(() => {
    if (isShowing) {
      window.scrollTo({
        top: document.body.scrollHeight / 3.7,
        left: 0,
        behavior: "smooth",
      });
      document.body.style.overflow = "hidden";
    }
  }, [isShowing]);

  return (
    isShowing && (
      <div className={styles.three_modal}>
        <div className={styles.wrapper}>
          <div className={styles.close_icon}>
            <CrossPolygon onClick={onHide} cardEditor={false} />
          </div>
          <Row className="mb-2 px-2 pb-2 pt-2">
            <h5 className={styles.subtext}>
              Find and purchase cards through the
            </h5>
            <h5 className={styles.subtext}>market place</h5>

            <div className={styles.raised_box}>
              <img
                className="img-fluid"
                src={PurchaseGif}
                style={{ width: "100%", height: "100%" }}
                alt="tour gif"
              />
            </div>

            <div className="d-flex flex-row justify-content-between align-items-center">
              <div className="d-flex">
                <div
                  className={styles.first_dot}
                  onClick={() => onStepClick(1)}
                ></div>
                <div
                  className={styles.dot}
                  onClick={() => onStepClick(2)}
                ></div>
                <div className={styles.third_dot}></div>
                <div
                  className={styles.dot}
                  onClick={() => onStepClick(4)}
                ></div>
                <div
                  className={styles.dot}
                  onClick={() => onStepClick(5)}
                ></div>
              </div>
              <div style={{ marginRight: "10px" }}>
                <PrimaryButton label="Next" onClick={onClick} />
              </div>
            </div>
          </Row>
        </div>
      </div>
    )
  );
};

export default StepThreeModal;
