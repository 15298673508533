import { SET_TOUR_STEP } from "./actions";

const initialState = {
  step: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_TOUR_STEP:
      return {
        ...state,
        step: action.payload,
      };

    default:
      return state;
  }
};
