import React from "react";
import { Modal, Row } from "react-bootstrap";

import styles from "./stepTwo.module.css";
import Watermark from "../../asset/watermark.svg";
import CrossPolygon from "../../component/Polygon/CrossPolygon";
import { PrimaryButton } from "../../component/Button/Button";

const StepTwo = ({ isShowing, onHide, onClick, onStepClick }) => {
  return (
    <Modal show={isShowing} size="lg" dialogClassName={styles.two_modal}>
      <div className={styles.wrapper}>
        <img src={Watermark} className={styles.watermark} alt="watermark" />
        <div className={styles.close_icon}>
          <CrossPolygon onClick={onHide} cardEditor={false} />
        </div>
        <Modal.Body>
          <Row>
            <h5 className={styles.title}>
              Hit <b>'Connect Wallet'</b> to connect your
            </h5>
            <h5 className={styles.subtext + " " + styles.m90}>
              Ethereum account to the application
            </h5>

            <div className="d-flex flex-row justify-content-between align-items-center">
              <div className="d-flex">
                <div
                  className={styles.first_dot}
                  onClick={() => onStepClick(1)}
                ></div>
                <div className={styles.second_dot}></div>
                <div
                  className={styles.dot}
                  onClick={() => onStepClick(3)}
                ></div>
                <div
                  className={styles.dot}
                  onClick={() => onStepClick(4)}
                ></div>
                <div
                  className={styles.dot}
                  onClick={() => onStepClick(5)}
                ></div>
              </div>
              <div>
                <PrimaryButton label="Next" onClick={onClick} />
              </div>
            </div>
          </Row>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default StepTwo;
