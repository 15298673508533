import React from "react";

// Assets and Styles
import styles from "./PurchaseModal.module.css";
import { ReactComponent as LeftArrow } from "../../../asset/leftArrow.svg";
import { ReactComponent as RightArrow } from "../../../asset/rightArrow.svg";

/**
 * A component that displays the quantity of the item.
 * @param quantity - the quantity of the item.
 * @param setQuantity - a function that sets the quantity of the item.
 * @returns None
 */
const Quantity = ({ quantity, setQuantity, maxQuantity }) => {
  const decrement = () => {
    quantity > 1 ? setQuantity((quantity = quantity - 1)) : setQuantity(1);
  };

  const increment = () => {
    quantity >= maxQuantity
      ? setQuantity((quantity = maxQuantity))
      : setQuantity((quantity = quantity + 1));
  };

  return (
    <div className={styles.quantity__box}>
      QUANTITY
      <div className={styles.quantity__boxRight}>
        {maxQuantity ? quantity : 0}
        <div>
          <div className={styles.icon__container} onClick={decrement}>
            <LeftArrow
              className={styles.arrow__icon}
              src={RightArrow}
              alt="Left Arrow"
            />
          </div>
          <div className={styles.icon__container} onClick={increment}>
            <RightArrow
              className={styles.arrow__icon}
              src={RightArrow}
              alt="Left Arrow"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quantity;
