import React, { useState } from "react";
import { Modal, Row, Col, Spinner } from "react-bootstrap";

import styles from "./WipeModal.module.css";
import { ReactComponent as Warning } from "../../../asset/warning.svg";

import CrossPolygon from "../../Polygon/CrossPolygon";
import { PrimaryButton } from "../../Button/Button";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { wipeCard } from "../../../redux/cards/actions";
import Loader from "../../Loader/Loader";

/**
 * A modal that appears when the user wants to wipe a card.
 * @param card - The card to wipe.
 * @param isShowing - Whether or not the modal is showing.
 * @param onHide - A function to call when the modal is hidden.
 * @returns A modal that appears when the user wants to wipe a card.
 */
const WipeModal = ({ card, isShowing, onHide }) => {
  const [wiping, setWiping] = useState(false);

  const blockchainReducer = useSelector((state) => state.blockchain);
  const dispatch = useDispatch();

  const wipeHandler = async () => {
    setWiping(true);
    try {
      const a = await dispatch(wipeCard(card.id, blockchainReducer.token));

      onHide();
    } catch (err) {
    } finally {
      setWiping(false);
    }
  };

  return (
    <Modal
      show={isShowing}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className={styles.modal}>
        <Row>
          <div className={styles.model__closeIcon}>
            <CrossPolygon onClick={onHide} cardEditor={false} />
          </div>
          <Col lg={9} md={9} sm={12}>
            <div className={styles.modal__card}>
              <div className={styles.card__imageContainer}>
                <Warning
                  className={styles.card__image}
                  src={Warning}
                  alt="CardImage"
                />
              </div>
              <div className={styles.card__body}>
                <div>
                  <div className={styles.heading}>WARNING</div>
                  <div className={styles.para}>
                    This action cannot be undone. Are you sure that you want to
                    wipe this card?
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={3} md={3} sm={12} className={styles.modal__right}>
            {wiping ? (
              <Loader />
            ) : (
              <PrimaryButton label="Proceed" onClick={wipeHandler} />
            )}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default WipeModal;
