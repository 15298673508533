import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { FaDiscord, FaTwitter } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

// Assets and Styles
import styles from "./Navbar.module.css";
import Logo from "../../asset/logo.png";
import TwitterIcon from "../../asset/twitter_hex.png";
import DiscordIcon from "../../asset/discord_hex.png";
// Redux
import {
  connectWeb3,
  connectRequest,
  connectionFailed,
} from "../../redux/User/actions";

//web3
import { providers } from "ethers";
import { web3Modal } from "../../web3ModalIns";
import Loader from "../Loader/Loader";

/**
 * A React component that renders the navbar.
 * @returns A React component that renders the navbar.
 */
const NavBar = () => {
  const dispatch = useDispatch();
  const blockchainReducer = useSelector((state) => state.blockchain);

  const connectWallet = async () => {
    if (blockchainReducer.loading || blockchainReducer.connected) return;

    try {
      dispatch(connectRequest());
      let connection = await web3Modal.connect();
      const ethersProvider = new providers.Web3Provider(connection);
      dispatch(connectWeb3(ethersProvider, connection));
    } catch (err) {
      dispatch(connectionFailed());
    }
  };

  return (
    <Navbar expand="lg" className={styles.navbar} id="navbar">
      <Navbar.Brand className={styles.navbar__brand}>
        <img alt="Logo" src={Logo} className={styles.navbar__logo} />
        CHAINCARDS
      </Navbar.Brand>
      <Navbar.Toggle className={styles.toggle} />
      <Navbar.Collapse className="justify-content-end">
        <Nav>
          <a
            href="https://ChainCards.io"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.navbar__link + " nav-link"}
          >
            Home
          </a>
          <a href="/#marketplace" className={styles.navbar__link + " nav-link"}>
            Marketplace
          </a>
          <a
            href="https://twitter.com/chaincards"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.navbar__icon}
          >
            <img src={TwitterIcon} alt="Twitter Icon" className={`img-fluid`} />
          </a>
          <a
            href="https://discord.gg/65kusexvTR"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.navbar__icon}
          >
            <img src={DiscordIcon} alt="Discord Icon" className={`img-fluid`} />
          </a>
          {blockchainReducer.connected ? (
            <Nav.Link
              style={{ letterSpacing: "0", width: "auto" }}
              className={styles.connect__button}
            >
              Connected to:
              <br />
              {blockchainReducer.addressString}
            </Nav.Link>
          ) : blockchainReducer.loading ? (
            <div className="col text-center">
              <Loader />
              <p>Connecting...</p>
            </div>
          ) : (
            <Nav.Link
              onClick={connectWallet}
              as={NavLink}
              to="/"
              className={styles.connect__button}
            >
              Connect Wallet
            </Nav.Link>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavBar;

const links = [
  {
    name: "Home",
    path: "ChainCards.io",
  },
  {
    name: "Marketplace",
    path: "/#marketplace",
  },
];
