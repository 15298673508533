const initialState = {
  loading: false,
  connected: false,
  account: null,
  balance: null,
  addressString: null,
  token: null,
  wallet: null,
  triggerChangeAccount: 0,
};

const blockchainReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CONNECTION_REQUEST":
      return {
        ...initialState,
        loading: true,
      };

    case "CONNECTION_SUCCESS":
      return {
        ...state,
        account: action.payload.account,
        balance: action.payload.balance,
        connected: true,
        loading: false,
        addressString: action.payload.addressString,
        wallet: action.payload.wallet,
        token: action.payload.token,
      };
    case "CONNECTION_FAILED":
      return {
        ...initialState,
        loading: false,
      };
    case "DISCONNECTED":
      return {
        ...initialState,
        loading: false,
        connected: false,
        account: null,
        balance: null,
        addressString: null,
        token: null,
        wallet: null,
      };
    case "ACCOUNTS_CHANGED":
      return {
        ...state,
        triggerChangeAccount: state.triggerChangeAccount + 1,
      };
    default:
      return state;
  }
};

export default blockchainReducer;
