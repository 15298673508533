import axios from "axios";
import web3 from "web3";
import API from "../../utils/API";
import { isValidWeb3Address } from "../../utils/utils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { defaultCards } from "../../data/Data";

export const loading = () => {
  return {
    type: "LOADING",
  };
};

const wipeCardSuccess = (payload) => {
  return {
    type: "WIPE_CARD_SUCCESS",
    payload: payload,
  };
};
export const setSelectedCard = (card) => {
  return {
    type: "SET_SELECTED_CARD",
    payload: card,
  };
};
const sendCardSuccess = (payload) => {
  return {
    type: "SEND_CARD_SUCCESS",
    payload: payload,
  };
};

export const clearCards = () => {
  return {
    type: "CLEAR_CARDS",
  };
};

const getCollectionsSuccess = (payload) => {
  return {
    type: "GET_COLLECTION_SUCCESS",
    payload: payload,
  };
};

/**
 * Gets all the collections from the database.
 */
export const getCollections = (
  limit = 10,
  offset = 0,
  search = "",
  tag = "",
  replace = false
) => {
  return async (dispatch) => {
    try {
      dispatch(loading());
      let res = await axios.get(
        API +
        `get-allCollections/?limit=${limit}&offset=${offset}&search=${search}&tag=${tag}`
      );
      let collections = res.data.collections;
      dispatch(
        getCollectionsSuccess({
          collections: collections,
          totalCollections: res.data.totalCollections,
          limit: limit,
          offset: offset,
          replace: replace,
        })
      );
    } catch (err) { }
    return {};
  };
};

/**
 * Clears all the collections from the Redux State.
 */
export const clearCollections = () => {
  return {
    type: "CLEAR_COLLECTION",
    payload: {},
  };
};

/**
 * Update the state of the cards in the My Cards section.
 * @param {object} payload - the payload to update the state with.
 * @returns {object} - an action object.
 */
const updateMyCards = (payload) => {
  return {
    type: "UPDATE_MY_CARDS",
    payload: payload,
  };
};

const updateRemainingCards = (payload) => {
  return {
    type: "UPDATE_REMAINING_CARDS",
    payload: payload,
  };
};

/**
 * Send a card to the server.
 * @param {string} address - the address of the recipient.
 * @param {Card} selectedCard - the card to send.
 * @param {string} account - the account of the sender.
 * @param {string} cardText - the text of the card.
 * @param {string} myId - the id of the sender.
 * @param {string} token - the token of the sender.
 * @param {string} key - the key of the sender.
 * @returns True if card is sent successfully
 */
export const sendCard = (
  address,
  selectedCard,
  account,
  cardText,
  myId,
  token,
  key
) => {
  return async (dispatch) => {
    try {
      const checkStatus = await axios.get(API + "status");

      account = account.trim();
      address = address.trim();

      if (!isValidWeb3Address(address)) {
        toast.error("Please enter a valid recipient's wallet address.");
        return;
      }
      // eslint-disable-next-line no-unused-vars
      let resp = await selectedCard.SmartContractObj.methods
        .safeTransferFrom(account, address, selectedCard.metaData["Token ID"])
        .send({
          from: account,
          // maxPriorityFeePerGas: null,
          // maxFeePerGas: null,
          // gasLimit: 1 * 10 ** 6,
        });

      const serverResp = await axios.post(
        API + "gift-card/",
        {
          cardId: selectedCard.id,
          receiver: address,
          cardText: cardText,
          senderId: myId,
          key,
        },
        {
          headers: {
            Authorization: "Bearer" + token,
          },
        }
      );

      dispatch(sendCardSuccess(selectedCard.id));
      toast.success("Card has been sent successfully!");
      return true;
    } catch (err) {
      toast.error("Could not send card!");
      console.log(err);
    }
  };
};

export const purchaseAndSendCard = (
  address,
  selectedCard,
  account,
  cardText,
  myId,
  token,
) => {
  return async (dispatch) => {
    let card = selectedCard;
    try {
      const checkStatus = await axios.get(API + "status");

      account = account.trim();
      address = address.trim();

      if (!isValidWeb3Address(address)) {
        toast.error("Please enter a valid recipient's wallet address.");
        return;
      }

      const price = await card.SmartContractObj.methods.cardPrice().call();
      const totalEth = web3.utils.fromDecimal(price.toString(), "ether");
      const resp = await card.SmartContractObj.methods
        .mint(1, address, false)
        .send({
          to: card.contractAddress,
          from: account,
          value: totalEth,
        });

      const Transfers = resp.events.Transfer;
      const tokenId = Number(Transfers.returnValues.tokenId)

      const serverResp = await axios.post(
        API + "purchase-card/",
        {
          collectionId: card.id,
          receiver: myId,
          tokenId: tokenId,
          tag: ["birthday", "congratulations"],
          isPurchaseAndSend: true,
          from: myId,
          to: address,
          cardText: cardText,
        },
        {
          headers: {
            Authorization: "Bearer" + token,
          },
        }
      );
      if (serverResp.data.message === "New card purchased successfully!") {
        const maxSupply = await card.SmartContractObj.methods.maxSupply().call();
        const totalSupply = await card.SmartContractObj.methods.totalSupply().call();
        const remaining = maxSupply - totalSupply;
        dispatch(updateRemainingCards({ collectionId: card.id, remaining, tokenId: tokenId + 1 }));
        const defaultCard = defaultCards[0]
        dispatch(setSelectedCard({ ...defaultCard }));
        toast.success("Card has been purchased and sent successfully!");
      }
      else {
        toast.error("Could not purchase and send card!");
      }
    } catch (err) {
      toast.error("Could not make purchase!");
      console.log("purchaseAndSend: Could not make purchase: ", err);
      return false;
    }
    return true;
  }
};



/**
 * Purchases a card.
 * @param {Card} card - the card to purchase
 * @param {number} quantity - the number of cards to purchase
 * @param {Wallet} wallet - the wallet to use to purchase
 * @param {string} token - the token to use to purchase
 * @returns True if card is purchased successfully
 */
export const purchaseCard = (card, quantity, wallet, token) => {
  return async (dispatch) => {
    const price = await card.SmartContractObj.methods.cardPrice().call();
    const totalPrice = price * quantity;
    let totalEth = web3.utils.fromDecimal(totalPrice.toString(), "ether");
    totalEth = String(totalEth);
    try {
      /**
       * third property is discount, its false for now:
       */
      const resp = await card.SmartContractObj.methods
        .mint(quantity, wallet.address, false)
        .send({
          to: card.contractAddress,
          from: wallet.address,
          // maxPriorityFeePerGas: null,
          // maxFeePerGas: null,
          // gasLimit: 1 * 10 ** 6,
          value: totalEth,
        });

      const Transfers = resp.events.Transfer;

      if (Transfers.length) {
        let cards = [];
        for (let i = 0; i < Transfers.length; i++) {
          cards.push({
            receiver: wallet.id,
            tokenId: Number(Transfers[i].returnValues.tokenId),
            tag: ["birthday", "congratulations"],
          });
        }

        const body = { cards: cards, collectionId: card.id };
        const serverResp = await axios.post(
          API + "purchase-multipleCards/",
          body,
          {
            headers: {
              Authorization: "Bearer" + token,
            },
          }
        );
        let newCards = serverResp.data.purchasedCards;

        dispatch(updateMyCards({ newCards: newCards }));
        toast.success("Cards have been purchased successfully!");
      } else {
        const serverResp = await axios.post(
          API + "purchase-card/",
          {
            collectionId: card.id,
            receiver: wallet.id,
            tokenId: Number(Transfers.returnValues.tokenId),
            tag: ["birthday", "congratulations"],
          },
          {
            headers: {
              Authorization: "Bearer" + token,
            },
          }
        );

        const myCards = [serverResp.data.purchasedCard];
        dispatch(updateMyCards({ newCards: myCards }));
        toast.success("Card has been purchased successfully!");
      }
    } catch (err) {
      toast.error("Could not make purchase!");
    }
  };
};

/**
 * Mints a card privately.
 * @param {Card} card - the card to mint
 * @param {number} quantity - the number of cards to mint
 * @param {Wallet} wallet - the wallet of the owner
 * @param {string} recipient - the recipient address to receive the minted cards
 * @param {string} token - the token to use for authentication
 * @returns True if cards are minted successfully
 */
export const privateMintCard = (card, quantity, wallet, recipient, token) => {
  return async (dispatch) => {
    try {
      // Call the privateMint function from the smart contract
      const resp = await card.SmartContractObj.methods
        .privateMint(quantity, recipient)
        .send({
          to: card.contractAddress,
          from: wallet.address,
        });

      const Transfers = resp.events.Transfer;

      if (Transfers.length) {
        let cards = [];
        for (let i = 0; i < Transfers.length; i++) {
          cards.push({
            receiver: recipient,
            tokenId: Number(Transfers[i].returnValues.tokenId),
            tag: ["birthday", "congratulations"],
          });
        }

        const body = { cards: cards, collectionId: card.id };
        const serverResp = await axios.post(API + "mint-multipleCards/", body, {
          headers: {
            Authorization: "Bearer" + token,
          },
        });
        // let newCards = serverResp.data.mintedCards;

        // dispatch(updateMyCards({ newCards: newCards }));
        toast.success("Cards have been minted privately successfully!");
      } else {
        const serverResp = await axios.post(
          API + "mint-card/",
          {
            collectionId: card.id,
            receiver: recipient,
            tokenId: Number(Transfers.returnValues.tokenId),
            tag: ["birthday", "congratulations"],
          },
          {
            headers: {
              Authorization: "Bearer" + token,
            },
          }
        );

        // const myCards = [serverResp.data.mintedCard];
        // dispatch(updateMyCards({ newCards: myCards }));
        toast.success("Card has been minted privately successfully!");
      }
    } catch (err) {
      toast.error("Could not mint card privately!");
      console.log(err);
    }
  };
};

const setPreviewImage = (previewImage) => {
  return {
    type: "SET_PREVIEW_IMAGE",
    payload: previewImage,
  };
};

/**
 * Takes in a string of code and sends it to the backend to be compiled and rendered.
 * @param {string} body - the code to be compiled and rendered.
 * @param {string} token - the token to be used to authenticate the request.
 * @returns None
 */
export const previewImage = (body, token) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(API + "previewImage", body, {
        headers: {
          Authorization: "Bearer" + token,
        },
      });

      dispatch(setPreviewImage(response.data.previewImage));
    } catch (err) { }
  };
};

/**
 * Wipes a card from the database.
 * @param {string} cardId - the id of the card to wipe.
 * @param {string} token - the token of the user.
 * @returns None
 */
export const wipeCard = (cardId, token) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        API + "wipe-card",
        { cardId: cardId },
        {
          headers: {
            Authorization: "Bearer" + token,
          },
        }
      );

      dispatch(wipeCardSuccess(response.data.cardInfo));
      toast.success("Card has been successfully wiped!");
    } catch (err) {
      toast.error("Could not wipe card!");
    }
  };
};
