import React, { useId } from "react";

//  Styles and Assets
import styles from "./InlineCard.module.css";
import CardLogo from "../../asset/card-logo.svg";
import { useSearchParams } from "react-router-dom";

// const placeholder = `Dear -----,

// Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.

// Warm regards,
// -----`;

const placeholder = "";

const InlineCard = ({
  cardText,
  cardImage,
  className,
  onClick,
  showArtistName,
  showSupply,
  artistName,
  remainingSupply,
  editionSize,
  cardPrice,
  ethPrice,
}) => {
  const [searchParams] = useSearchParams();
  const id = useId();
  const data = Object.fromEntries([...searchParams]);
  if (data.text === undefined) {
    data.text = cardText;
  }
  if (data.image === undefined) {
    data.image = cardImage;
  }
  const onClickHandler = (e, data) => {
    e.preventDefault();
    onClick(e, data);
    //find all the elements with the same class name
    const elements = document.getElementsByClassName(`InlineCard`);
    //remove the class 'round-borders' from all the elements
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove("round-borders");
    }
    //add the class 'round-borders' to the clicked element
    e.target.classList.add("round-borders");
  };
  return (
    <div
      className={`${className} ${styles.selected__cardContainer} ${styles.card__overlay} InlineCard`}
      onClick={onClickHandler}
      style={{
        backgroundImage: `url(${data.image})`,
      }}
      id={`InlineCard-${id}`}
    >
      <div className={styles.selected__card}>
        <div className={styles.card__imageContainer}></div>
        <div className={styles.card__body}>
          <div className={styles.card__bodyLogo}>
            <img className="img-fluid" src={CardLogo} alt="CardLogo" />
          </div>
          <div>
            <svg
              width="100%"
              height="75%"
              viewBox="0 0 500 520"
              preserveAspectRatio="xMinYMin meet"
            >
              <foreignObject
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/1999/xhtml"
              >
                <div className={styles.card__textContainer}>
                  {/* Check showArtistName and showSupply, if both are true return a div otherwise a textarea */}
                  {showArtistName && showSupply ? (
                    <div
                      className={
                        styles.card__text +
                        " " +
                        styles.card__artistNameAndSupply
                      }
                    >
                      <>
                        <div className={styles.card__artistName}>
                          {artistName.split(" ").map((word, id) => (
                            <div key={id}> {word} </div>
                          ))}
                        </div>
                        <div className={styles.card__supply}>
                          {remainingSupply} / {editionSize}
                        </div>
                      </>
                      {/* Card Price and Eth in USD */}
                      <>
                        <div className={styles.card__cardPrice}>
                          {cardPrice} ETH
                        </div>
                        <div className={styles.card__ethPrice}>
                          {(ethPrice * cardPrice).toFixed(3)} USD
                        </div>
                      </>
                    </div>
                  ) : (
                    <textarea
                      className={styles.card__text}
                      value={data.text || placeholder}
                      maxLength={600}
                      disabled={true}
                    />
                  )}
                </div>
              </foreignObject>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InlineCard;
