import React, { useState, useEffect } from "react";

//  Styles and Assets
import styles from "./SelectedCard.module.css";
import CardLogo from "../../asset/card-logo.svg";
import Polygon from "../../asset/polygon-white.svg";
import { placeholder } from "../../data/Data";

/**
 * A component that renders the selected card.
 * @param text - The text to display in the card.
 * @param setText - A function to set the text in the card.
 * @param data - The data contains everything for the Card.
 * @param clearRef - A function to clear the reference to the card.
 * @param exportRef - A function to export the reference to the card.
 * @returns A component that renders the selected card.
 */

const SelectedCard = ({ text, setText, data, clearRef, exportRef }) => {
  const limit = 600;
  const [dynamicLength, setDynamicLength] = useState(limit);
  let [count, setCount] = useState(limit - 20);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setText("");
    setDynamicLength(limit);
    setCount(limit - 20);
    setShow(false);
  }, [data]);

  const handleChange = (e) => {
    let enters = e.target.value.split("\n").length - 1;
    let totalWords = e.target.value.length + 33 * enters - enters;
    let remainingChars = limit - totalWords - enters;
    remainingChars = remainingChars < 0 ? 0 : remainingChars;
    if (totalWords > limit) totalWords = limit;

    if (remainingChars <= 20) {
      setShow(true);
      setCount(remainingChars);
    } else {
      setShow(false);
    }
    setDynamicLength(limit - enters * 33 < 0 ? 0 : limit - enters * 33);
    setText(e.target.value);
  };
  return (
    <div
      ref={exportRef}
      className={styles.selected__cardContainer}
      style={{ backgroundImage: `url(${data.imageUrl})` }}
      id="SelectedCard"
    >
      <div className={styles.selected__card}>
        <div className={styles.card__imageContainer}></div>
        <div className={styles.card__body}>
          <div className={styles.card__bodyLogo}>
            <img className="img-fluid" src={CardLogo} alt="CardLogo" />
          </div>

          <svg
            width="100%"
            height="75%"
            viewBox="0 0 500 520"
            preserveAspectRatio="xMinYMin meet"
          >
            <foreignObject
              width="100%"
              height="100%"
              xmlns="http://www.w3.org/1999/xhtml"
            >
              <div className={styles.card__textContainer}>
                <textarea
                  ref={clearRef}
                  className={styles.card__text}
                  value={data.status === "received" ? data.cardText : text}
                  placeholder={
                    data.status !== "received" ? placeholder : data.cardText
                  }
                  onChange={handleChange}
                  maxLength={dynamicLength}
                  disabled={data.status === "received" ? true : false}
                />
              </div>
              {show ? (
                <div className={styles.count}>
                  <div className={styles.polygon}>
                    <img src={Polygon} alt="Polygon" />
                    <span>{count}</span>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </foreignObject>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default SelectedCard;
