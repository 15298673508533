import { useEffect, useState } from "react";

const useFilter = (data) => {
  const [filteredData, setFilteredData] = useState();
  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const filter = (objValue, objKey) => {
    if (objValue === "*") {
      const filtered = data.filter((item) => {
        return (
          item[objKey] === "received" ||
          item[objKey] === "wiped" ||
          item[objKey] === "purchased"
        );
      });
      setFilteredData(filtered);
    } else if (objValue !== "") {
      const filtered = data.filter((item) => {
        return item[objKey].toLowerCase() === objValue.toLowerCase();
      });
      setFilteredData(filtered);
    } else {
      setFilteredData(data);
    }
  };
  return {
    filteredData,
    filter,
  };
};

export default useFilter;
