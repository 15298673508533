import React from "react";

// Components
import AvailableCards from "../../component/AvailableCards/AvailableCards";
import CardEditor from "../../component/CardEditor/CardEditor";
import Footer from "../../component/Footer/Footer";
import { Container } from "react-bootstrap";

/**
 * The main component of the application.
 * @returns None
 */
const Home = () => {
  return (
    <>
      <div className="app">
        <CardEditor />
        <AvailableCards />
        <Footer />
      </div>
    </>
  );
};

export default Home;
