import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Spinner,
  OverlayTrigger,
  Popover,
  Button,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";
import Web3EthContract from "web3-eth-contract";
import { ToastContainer, toast } from "react-toastify";
import { setSelectedCard as setSelectedCardOnRedux } from "../../redux/cards/actions";

// Assets and Styles
import styles from "./AvailableCards.module.css";
import InfoIcon from "../../asset/Info-icon-2.gif";

// Utils
import { keywords } from "../../data/Data";
import useFilter from "../../utils/customHooks/useFilter";
import useModal from "../../utils/customHooks/useModal";

// Redux and Contracts
import { setStep } from "../../redux/tour/actions";
import collectionContract from "../../contractAbis/collection.json";
import { clearCollections, getCollections } from "../../redux/cards/actions";

// Components
import { FilterButton, PrimaryButton } from "../Button/Button";
import { SearchInput } from "../Input/Input";
import PurchaseModal from "../Modals/PurchaseModal/PurchaseModal";
import StepThreeModal from "./StepThreeModal";
import Card from "../Card/Card";
import Loader from "../Loader/Loader";
import axios from "axios";

/**
 * A component that renders the available cards.
 * @param props - The props for the component.
 * @returns A component that renders the available cards.
 */

const AvailableCards = (props) => {
  const { setStep } = props;

  const [active, setActive] = useState("All Cards");
  const [selectedCard, setSelectedCard] = useState(null);
  // selected card on the redux store
  const selectedCardOnRedux = useSelector((state) => state.cards.selectedCard);
  const [purchasing, setPurchasing] = useState(false);
  const [warning, setWarning] = useState(false);
  const blockchainReducer = useSelector((state) => state.blockchain);
  const collections = useSelector((state) => state.cards.collections);
  const totalCollections = useSelector((state) => state.cards.totalCollections);
  const step = useSelector((state) => state.tour.step);
  const dispatch = useDispatch();
  const [filteredCollections, setFilteredCollections] = useState(collections);
  const { isShowing, show, hide } = useModal();
  const [limit, setLimit] = useState(6);
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState("");
  const [tag, setTag] = useState("");
  const [replace, setReplace] = useState(false);
  const containerRef = useRef(null);
  const [fetchingMoreCollections, setFetchingMoreCollections] = useState(false);
  const [ethPrice, setEthPrice] = useState(0);

  useEffect(() => {
    const getEthPrice = async () => {
      try {
        let res = await axios.get(
          "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd"
        );
        setEthPrice(res.data.ethereum.usd);
      } catch (err) {
        console.log(err);
      } finally {
      }
    };
    getEthPrice();
  }, []);

  useEffect(() => {
    warning && toast.error("Please select a card to purchase.");
    setWarning(false);
  }, [warning]);

  const fetchCollectionsByTags = async (
    limit = 6,
    offset = 0,
    search = "",
    tag = "",
    replace = false
  ) => {
    if (collections.length > 0) {
      setFetchingMoreCollections(true);
    }

    try {
      await dispatch(getCollections(limit, offset, search, tag, replace));
      setFetchingMoreCollections(false);
    } catch (error) {
      // Handle error if needed
    }

    setReplace(false);
  };

  useEffect(() => {
    fetchCollectionsByTags(limit, offset, search, tag, replace);
    setFilteredCollections(collections);
  }, [limit, offset]);

  useEffect(() => {
    const container = containerRef.current;
    container.addEventListener("scroll", handleScroll);
    setFilteredCollections(collections);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [collections]);
  const purchaseHandler = () => {
    if (!(blockchainReducer.loading || blockchainReducer.connected)) {
      toast.error("Please connect an Ethereum Wallet.");
      return;
    } else if (
      selectedCard &&
      (selectedCardOnRedux.status === "live" ||
        selectedCardOnRedux.status === "sold")
    ) {
      setWarning(false);
      show();
    } else {
      setWarning(true);
      setTimeout(() => {
        setWarning(false);
      }, 3000);
    }
  };
  const purchaseHandlerForSend = () => {
    if (!(blockchainReducer.loading || blockchainReducer.connected)) {
      toast.error("Please connect an Ethereum Wallet.");
      return;
    } else if (
      selectedCard &&
      (selectedCardOnRedux.status === "sold" ||
        selectedCardOnRedux.status === "live")
    ) {
      // move window to #SelectedCard
      window.location.href = "#navbar";
    } else {
      toast.error(
        "To purchase and send a card in a single transaction, please select a card from the marketplace."
      );
    }
  };
  const handleScroll = () => {
    const container = containerRef.current;

    const totalDistance = container.scrollHeight;
    let scrolledDistance = container.scrollTop + container.clientHeight;
    scrolledDistance += (scrolledDistance / totalDistance) * 50;
    if (
      collections.length < totalCollections &&
      scrolledDistance >= totalDistance
    ) {
      setOffset((prevOffset) => prevOffset + limit);
    }
  };

  const onClickHandler = async (e, data) => {
    e.preventDefault();
    const SmartContractObj = new Web3EthContract(
      collectionContract,
      data.contractAddress
    );
    let card = { ...data, SmartContractObj };
    setSelectedCard(card);
    setSelectedCard((prevData) => {
      const newData = { ...prevData, SmartContractObj };
      newData.metaData = {};
      newData.metaData["Artist"] = prevData.metaData["Artist"];
      newData.metaData["Release Date"] = prevData.metaData["Release Date"];
      newData.metaData["Series"] = prevData["keyword"];
      newData.metaData["Edition Size"] = prevData.metaData["Edition Size"];
      newData.metaData["Remaining"] = prevData.metaData["Remaining"];
      newData.metaData["Card Price"] = prevData.metaData["Card Price"] + " ETH";
      newData.metaData["Total Price"] = `${newData.metaData["Card Price"]} (${(
        newData.metaData["Card Price"].split(" ")[0] * prevData.ethPrice
      ).toFixed(2)} USD)`;
      card = newData;
      return newData;
    });

    dispatch(setSelectedCardOnRedux(card));
  };

  const filterHandler = (search, tag) => {
    if (tag) {
      setActive(tag);
      if (tag === "All Cards") {
        setTag("");
        tag = "";
      }
    } else setActive("All Cards");
    setSelectedCard(null);
    // word === "All Cards" ? filter("", "keyword") : filter(word, "keyword");
    setSearch(search);
    setTag(tag);
    setOffset(0);
    setReplace(true);
    dispatch(clearCollections());
    fetchCollectionsByTags(limit, offset, search, tag, true);
    //find all the elements with the same class name
    const elements = document.getElementsByClassName(`InlineCard`);
    //remove the class 'round-borders' from all the elements
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove("round-borders");
    }
  };

  const hideHandler = () => {
    setSelectedCard(null);
    //find all the elements with the same class name
    const elements = document.getElementsByClassName(`InlineCard`);
    //remove the class 'round-borders' from all the elements
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove("round-borders");
    }
    hide();
  };

  const handleComplete = () => {
    setStep(null);
    localStorage.setItem("tourCompleted", "yes");
  };

  const popoverHoverFocus = (
    <Popover id="popover-trigger-hover-focus" title="Popover bottom">
      <div className="p-2">
        Select a card from the marketplace and use the app above to Purchase and
        Send in a single transaction.
      </div>
    </Popover>
  );
  return (
    <>
      <Container fluid className={styles.marketplace} id="marketplace">
        <Container className={styles.available__cardsContainer}>
          <Row className={styles.filter__buttons}>
            <Col sm={11}></Col>
            <Col sm={1} className="d-flex justify-content-end">
              <OverlayTrigger
                trigger={["hover", "focus"]}
                placement="bottom"
                overlay={popoverHoverFocus}
              >
                <div className="d-inline-block">
                  <span>
                    <img
                      src={InfoIcon}
                      alt="info"
                      className={"img-fluid my-2 " + styles.infoIcon}
                    />
                  </span>
                </div>
              </OverlayTrigger>
            </Col>
            <Col sm={12}>
              {step === 3 && (
                <StepThreeModal
                  isShowing={step === 3}
                  onClick={() => setStep(4)}
                  onHide={handleComplete}
                  onStepClick={(val) => setStep(val)}
                />
              )}
            </Col>
            <Col lg={6}>
              {React.Children.toArray(
                keywords.map((word) => (
                  <FilterButton
                    active={active}
                    label={word}
                    onClick={() => filterHandler("", word)}
                  />
                ))
              )}
            </Col>
            <Col lg={6}>
              <Row>
                <Col md={12} lg={6} className={"text-center"}>
                  <SearchInput
                    label="Search Cards"
                    type="text"
                    placeholder="Search Cards"
                    value={search}
                    onType={(e) => filterHandler(e.target.value, "")}
                  />
                </Col>
                <>
                  <Col md={12} lg={3} className={styles.purchaseCol}>
                    {purchasing ? (
                      <Loader />
                    ) : (
                      <PrimaryButton
                        label="Purchase"
                        onClick={purchaseHandler}
                      />
                    )}
                  </Col>
                  <Col md={12} lg={3} className={styles.purchaseCol}>
                    {purchasing ? (
                      <Loader />
                    ) : (
                      <>
                        <PrimaryButton
                          label="Send"
                          onClick={purchaseHandlerForSend}
                        />
                      </>
                    )}
                  </Col>
                </>
              </Row>
            </Col>
          </Row>
          <div ref={containerRef} className={styles.cards__container}>
            <div className={styles.card__list}>
              <div className={styles.cards__subContainer}>
                {filteredCollections.length ? (
                  <>
                    {filteredCollections.map((data, index) => (
                      <Card
                        key={data.id}
                        data={data}
                        purchasedCard={false}
                        onClick={(e) => onClickHandler(e, data)}
                        showArtistName={true}
                        showSupply={true}
                        ethPrice={ethPrice}
                      />
                    ))}
                  </>
                ) : (
                  <>
                    {fetchingMoreCollections ? (
                      <div style={{ width: "100%", textAlign: "center" }}>
                        <Loader />
                      </div>
                    ) : (
                      <h5>No Cards Available</h5>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </Container>
      </Container>
      {selectedCard && (
        <PurchaseModal
          purchasing={purchasing}
          setPurchasing={setPurchasing}
          data={selectedCard}
          setData={setSelectedCard}
          isShowing={isShowing}
          onHide={hideHandler}
          address={blockchainReducer.account}
          hide={hide}
          ethPrice={ethPrice}
        />
      )}
    </>
  );
};

const mapDispatchToProps = {
  setStep,
};

export default connect(null, mapDispatchToProps)(AvailableCards);
